import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 36 36",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      x: "36",
      width: "36",
      height: "36",
      rx: "18",
      transform: "rotate(90 36 0)",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M9.45468 17.3754L16.1027 10.7273C16.2414 10.5887 16.4293 10.5109 16.6254 10.5109C16.8214 10.5109 17.0094 10.5887 17.148 10.7273C17.2866 10.8659 17.3644 11.0539 17.3644 11.2499C17.3644 11.446 17.2866 11.6339 17.148 11.7726L11.7603 17.1593L26.2281 17.1593C26.424 17.1593 26.6119 17.2372 26.7504 17.3757C26.889 17.5142 26.9668 17.7021 26.9668 17.898C26.9668 18.0939 26.889 18.2818 26.7504 18.4203C26.6119 18.5589 26.424 18.6367 26.2281 18.6367L11.7603 18.6367L17.148 24.0235C17.2866 24.1621 17.3644 24.3501 17.3644 24.5461C17.3644 24.7421 17.2866 24.9301 17.148 25.0687C17.0094 25.2073 16.8214 25.2852 16.6254 25.2852C16.4293 25.2852 16.2414 25.2073 16.1027 25.0687L9.45468 18.4206C9.386 18.352 9.33152 18.2705 9.29435 18.1809C9.25717 18.0912 9.23804 17.9951 9.23804 17.898C9.23804 17.8009 9.25717 17.7048 9.29435 17.6151C9.33152 17.5255 9.386 17.444 9.45468 17.3754Z",
      fill: "white"
    }, null, -1)
  ])))
}
export default { render: render }